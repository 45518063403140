<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem;">
          <div @click="addUser" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Creează utilizator
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :startup="startTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :prepareItemFn="x => ({...x.user, ...x})"
        :apiModule="tableModule"
        :apiModuleName="tableModuleString"
        :tableMinimizeHead="true"
        :tableSpread="true"
        :disabledCols="adminMode ? [] : [1]"
        @Btn2="editUser"
        @Btn3="delUser"
        @Btn8="i => statusUser(i, true)"
        @Btn9="i => statusUser(i, false)"
        @Btn13="viewHistory"
        @totalRecords="x => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from '@/mixins/usersPermMixin.js'
import {
  USERS_PAGINATED,
  USERS_ORIGIN_PAGINATED,
  USER_MODIFY,
  USERS_FROM_INSTITUTION,
  USERS_ONCE,
  USERS_CHECK_ROLE,
} from '../api.js'
import { mapGetters, mapMutations } from 'vuex'
import TableParent from '@/components/TableParent'
import DialogModal from '@/components/DialogModal'

const posIcon = {
  border: 'left',
  tooltip: 'Activ',
  css: 'font-size:2.2rem;color:green;',
  icon: 'fas fa-check-circle',
}
const negIcon = {
  border: 'left',
  tooltip: 'Dezactivat',
  css: 'font-size:2.2rem;color:red;',
  icon: 'fas fa-exclamation-circle',
}
const uncIcon = {
  border: 'left',
  tooltip: 'Neconfirmat',
  css: 'font-size:2.2rem;color:#f2da07;',
  icon: 'far fa-clock',
}
const statusesMap = {
  active: posIcon,
  disabled: negIcon,
  reset: posIcon,
  unconfirmed: uncIcon,
}

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`
  },
  inject: ['usr', 'userStat'],
  mixins: [PermisionsMixin],
  components: {
    TableParent,
  },
  data() {
    return {
      reloadTable: 0,
      tableHead: [
        {
          title: 'Rol utilizator',
          queryKey: 'role',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Data creării',
          queryKey: 'createdAt',
          bottomHead: {
            quikFiltre: 'date',
            range: true,
          },
        },
        {
          title: 'Prenume',
          queryKey: 'last_name',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Nume',
          queryKey: 'first_name',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'ID / ConectX',
          queryKey: 'id',
          bottomHead: {
            quikFiltre: 'number',
            exactSearch: true,
          },
        },
        {
          title: 'Username',
          queryKey: 'email',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Telefon',
          queryKey: 'phone',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: 'userStatus',
          bottomHead: {
            tooltip: 'Status utilizator',
            quikFiltre: 'userStatus',
          },
        },
      ],
      startTable: false,
      tableModule: false,
      fromInstitution: '',
      tableModuleString: '',
      totalRecords: 0,
    }
  },
  computed: {
    ...mapGetters(['userRole']),
    usersFromOrigin() {
      return this.userRole?.role && this.userRole.role !== 'superadmin'
    },
    isFromInstitution() {
      return +this.fromInstitution > 0
    },
    adminMode() {
      return this.usersFromOrigin || this.isFromInstitution
    }
  },
  watch: {
    usr: {
      handler() {
        this.reloadPage()
      },
      deep: true,
    },
    userStat: {
      handler(x) {
        const y = x?.Ustatus
        if (y) {
          this.statusUser(y.userIndex, y.status, y.reason)
        }
      },
      deep: true,
    },
    $query() {
      this.syncVuss()
    },
    adminMode: {
      handler(x) {
        this.syncApi()

        const y = this.tableHead
        const setQueries = fromOrigin => {
          for (let i = fromOrigin ? 3 : 0; i < y.length; i++) {
            const e = y[i];
            if(e.queryKey) {
              e.queryKey = fromOrigin ? 'user.' + e.queryKey : e.queryKey.replace('user.', '')
              
              if(typeof e.bottomHead?.exactSearch == 'boolean') {
                e.bottomHead.exactSearch = !fromOrigin
              }
            }
          }
        }

        if(x) {
          setQueries(true)
        } else {
          setQueries(false)
        }
        this.reloadPage()
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['addWindow', 'delWindow']),
    reloadPage() {
      this.reloadTable++
      this.totalRecords = 0
    },
    getApiModule(stringMode) {
      const apiMap = {
        'false': USERS_PAGINATED,
        'true': USERS_ORIGIN_PAGINATED
      }
      const find = this.isFromInstitution ? (
        stringMode ? USERS_FROM_INSTITUTION.name : (
          (...p) => USERS_FROM_INSTITUTION(this.fromInstitution, ...p)
        )
      ) : apiMap[this.usersFromOrigin]

      return find ? (stringMode ? find.name : find) : apiMap[0]
    },
    syncApi() {
      this.tableModule = this.getApiModule()
      this.tableModuleString = this.getApiModule(true)
    },
    delUser(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e('Anunțați administratorul. #981236')
        return
      }
      this.addWindow({
        title: 'Șterge utilizator',
        subtitle: this.readUserObject(item),
        data: item,
        modal: 20.2,
      })
    },
    editUser(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e('Anunțați administratorul. #981236')
        return
      }

      this.addWindow({
        title: 'Editare utilizator',
        subtitle:
          this.readUserObject(item) +
          (x =>
            x
              ? ` / ${
                  {
                    active: 'Activ',
                    reset: 'Activ',
                    disabled: 'Dezactivat',
                    unconfirmed: 'Neconfirmat',
                  }[x]
                }`
              : '')(item.userStatus),
        data: item,
        modal: 20.1,
      })
    },
    viewHistory(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e('Anunțați administratorul. #981236')
        return
      }

      this.addWindow({
        title: 'Istoric',
        subtitle: this.readUserObject(item),
        versions: item.history,
        modal: 23,
      })
    },
    statusUser(item, pos, reason) {
      if (!item.uuid) {
        this.$toastr.e('Anunțați administratorul.')
        return
      }
      if (!this.isString(reason)) {
        this.addWindow({
          title: `${pos ? 'Activare' : 'Dezactivare'} utilizator`,
          subtitle: this.readUserObject(item),
          labels: pos
            ? ['Activat', 'activării', 'Observații']
            : ['Dezactivat', 'dezactivării', 'Motivul dezactivării'],
          button: pos
            ? { value: 'Activează', type: 2 }
            : { value: 'Dezactivează', type: 3 },
          userIndex: item,
          status: pos,
          modal: 11,
        })

        if (!pos) {
          const closeModal = () => {
            this.delWindow()
            this.setLoad()
          }
          const error = msg => {
            this.$toastr.e(
              msg || 'Verificarea utilizatorului a eșuat.'
            )
            closeModal()
          }
          const canDeleteUser = () => {
            this.setLoad()
          }

          const uuid = item?.uuid || null

          this.setSafeLoad()
          if (this.isString(uuid)) {
            USERS_ONCE(uuid)
              .then(res => {
                if(!this.isObject(res?.data?.result)) {
                  return
                }

                res = res.data.result

                if (Array.isArray(res?.userRoles)) {
                  const promises = []

                  for (let e of res.userRoles) {
                    promises.push(USERS_CHECK_ROLE(e.id))
                  }

                  const roleChecker = res => {
                    if (Array.isArray(res?.data?.result)) {
                      const canDelete = !res.data.result.find(
                        e => e.status == 'avizo'
                      )

                      if (canDelete) {
                        if (!res.data.result.length) {
                          // Grant deleting
                          return true
                        } else {
                          // Ask for deleting
                          return 1
                        }
                      } else {
                        // Forbid deleting
                        return 2
                      }
                    } else {
                      return false
                    }
                  }

                  this.setSafeLoad(12000)
                  Promise.all(promises)
                    .then(res => {
                      let askForDeleting = false

                      for(let e of res) {
                        const x = roleChecker(e)

                        if(!x) {
                          error()
                          return
                        } else {
                          if(x === 2) {
                            this.$toastr.Add({
                              msg:
                                'Utilizatorul selectat de dvs. nu poate fi dezactivat deoarece acesta deține roluri care participă în procesul de avizare pentru unele necesități.',
                              timeout: 10000,
                              type: 'warning',
                            })
                            closeModal()
                            return
                          }
                          if(x === 1) {
                            askForDeleting = true
                          }
                        }
                      }

                      if(askForDeleting) {
                        this.setLoad()
                        let confirmed = false

                        this.$modal.show(
                          DialogModal,
                          {
                            target: 'UserDelete',
                            title: 'Ștergere utilizator',
                            content:
                              '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Utilizatorul selectat de dvs. poate avea roluri care pot fi ca aprobator pentru unele necesități.</p></span>',
                            closeBtnText: 'Anulează',
                            button: {
                              type: 3,
                              value: 'Confirmă',
                              handler: () => {
                                confirmed = true
                                canDeleteUser()
                              },
                            },
                          },
                          {
                            name: 'UserDelete',
                            adaptive: true,
                            width: '650',
                            height: '400',
                          },
                          {
                            'before-close': () => {
                              window.requestAnimationFrame(() => {
                                if(!confirmed) {
                                  closeModal()
                                }
                              })
                            },
                          }
                        )
                      } else {
                        canDeleteUser()
                      }
                    })
                    .catch(error)
                } else {
                  error()
                }
              })
              .catch(error)
          } else {
            setTimeout(error, 500)
          }
        }
        return
      }
      const x = item.uuid
      USER_MODIFY(x, {
        userStatus: pos ? 'active' : 'disabled',
        actionReason: reason || ' ',
      })
        .then(res => {
          if (
            !res ||
            (res.hasOwnProperty('statusCode') &&
              String(res.statusCode).at(0) !== '2')
          ) {
            this.$toastr.e('Încercați mai târziu')
          } else {
            this.$toastr.s(
              'Utilizatorul a fost ' + (pos ? 'activat.' : 'dezactivat.')
            )
            if (!pos && x === this.getUuid()) {
              this.logout()
            }
          }
          this.reloadPage()
        })
        .catch(err => {
          this.$toastr.e(err)
          this.reloadPage()
        })
    },
    addUser() {
      this.addWindow({
        title: 'Creează utilizator',
        modal: 20,
      })
    },
    prepareData(row) {
      row.id = row.user?.id ?? row.id

      let role = ''
      let userStatus = row.user?.userStatus || row.userStatus
      let { createdAt } = row
      if (this.adminMode) {
        role = row.role || ''
        row = row?.user || row || {}
        createdAt = createdAt || row.createdAt
      }

      const preparation = [
        this.toDateAndTime(createdAt),
        row.first_name || '-',
        row.last_name || '-',
        Number.isInteger(row.id) ? row.id : '-',
        row.email || '-',
        row.phone || '-',
        statusesMap[userStatus] || '',
      ]
      if(this.adminMode) preparation.unshift(this.changeRoleView(role) || '')

      const uuid = row.createdByUser
      const x = this.PERMISIONS
      const prepareBtn = []

      const checkOwner = o => {
        if(!o) return false
        if(o === true) return true
        if(o == uuid) {
          return true
        }
        if(Array.isArray(o) && o[1] == uuid) {
          return true
        }
        return false
      }

      if (checkOwner(x.edit)) {
        prepareBtn.push(2)
      }
      if (userStatus === 'disabled' && checkOwner(x.enableUser)) {
        prepareBtn.push([8, 'Activează utilizatorul'])
      } else if (
        (['active', 'reset'].includes(userStatus) &&
          x.disableUser === true) && checkOwner(x.disableUser)
      ) {
        prepareBtn.push([9, 'Dezactivează utilizatorul'])
      }
      if (checkOwner(x.delete)) {
        prepareBtn.push(3)
      }
      if(userStatus == 'unconfirmed') {
        prepareBtn.push(3)
      }
      prepareBtn.push(13)
      if (prepareBtn.length) {
        preparation.push([prepareBtn])
      }

      return preparation
    },
    syncVuss() {
      if (this.$vuss.q.exists('from_institution')) {
        this.$vuss.q.sync('fromInstitution')
      }
    },
  },
  beforeMount() {
    this.syncVuss(true)
  },
  async created() {
    this.syncApi()

    if (!this.PERMISIONS.list) {
      return
    }
    if (this.checkParamsInObj(this.PERMISIONS, ['edit'])) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true
        },
        bottomHead: {
          quikFiltre: 'clear',
        },
      })
    }
  },
  mounted() {
    this.startTable = true
  }
}
</script>
