export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        edit: false,
        delete: false,
        enableUser: false,
        disableUser: false
      }
    }
  },
  created() {
    const baseRule = 'usersManagement'

    this.initPermision(`${baseRule}.get`,'list')
    this.initPermision(`${baseRule}.post`,'create')
    this.initPermision(`${baseRule}.patch`,'edit')
    this.initPermision(`${baseRule}.delete`,'delete')
    this.initPermision(`${baseRule}.enableUser`,'enableUser')
    this.initPermision(`${baseRule}.disableUser`,'disableUser')
  }
}